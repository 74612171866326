// ContactSupportContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader, multiFormHeader } from "../apis/header";

const ContactSupportContext = createContext();

export const ContactSupportContextProvider = ({ children }) => {
  const [allQueries, setAllQueries] = useState(null);

  const getAllQueries = async (token) => {
    try {
      const headers = jsonHeader(token)

      const response = await ApiTemplate("get", "/api/v1/get/customer/support", {}, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setAllQueries(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const addAllQueries = async (token, blogData) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("post", "/api/v1/store/post", blogData, headers);
      if (response && response["success"] === true) {

        await getAllQueries(token);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };


  return (
    <ContactSupportContext.Provider
      value={{
        allQueries,
        getAllQueries,
        addAllQueries,
      }}
    >
      {children}
    </ContactSupportContext.Provider>
  );
};

export const useContactSupportContext = () => {
  const blogsContextValue = useContext(ContactSupportContext);
  return blogsContextValue;
};
