import React, { useEffect, useState } from 'react'
import { useEventContext } from '../../../contexts/EventContextProvider';
import { useAuthContext } from '../../../contexts/AuthContextProvider';
import { useIconsContext } from '../../../contexts/IconsContextProvider';
import { baseUrl } from '../../../apis/base_url';
import Select, { components } from 'react-select';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';


function AddFeatures({ onNext }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { addEventFeatures, addEventId } = useEventContext();
  const { token, } = useAuthContext();
  const { allIcons, getIcons, } = useIconsContext();
  const [formData, setFormData] = useState({
    icon_id: null,
    title: "",
    description: '',

  });
  const [options, setOptions] = useState([])
  const [featureList, setFeatureList] = useState([
    {
      icon_id: null,
      title: '',
      description: '',
    },
  ]);

  const handleAddFeature = () => {
    setFeatureList([...featureList, { icon_id: null, title: '', description: '' }]);
  };

  const handleDeleteFeature = (index) => {
    const updatedFeatureList = featureList.filter((_, i) => i !== index);
    setFeatureList(updatedFeatureList);
  };

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      // Handling file inputs differently
      setFormData({
        ...formData,
        [name]: files[0], // Assuming only a single file is selected
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const getAllIcons = async () => {
    await getIcons(token);
  }

  useEffect(() => {
    getAllIcons();
  }, []);

  

  useEffect(() => {

    setOptions(allIcons ? allIcons.map((icon) => ({
      value: icon.id,
      label: (
        <div>
          <img src={`${baseUrl}/${icon.path}`} alt='Icon' width={20} height={20} />
        </div>
      ),
      image: `${baseUrl}/${icon.path}`,
    })) : [])
  }, [allIcons]);



  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      width: '100%',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
  };

  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }} className="absolute top-2 left-2">
      <img src={data.image} alt='Selected Icon' width={20} height={20} />
    </div>
  );

  const handleFeatureInputChange = (index, e) => {
    const { name, value, type, files } = e.target;
    const updatedFeatureList = [...featureList];
    if (type === 'file') {
      updatedFeatureList[index] = {
        ...updatedFeatureList[index],
        [name]: files[0],
      };
    } else {
      updatedFeatureList[index] = {
        ...updatedFeatureList[index],
        [name]: value,
      };
    }
    setFeatureList(updatedFeatureList);
  };

   const handleNext = async () => {
    setLoading(true);
    const isAnyFieldEmpty = featureList.some(
      (field) =>
        field.icon_id === null || field.title === '' || field.description === ''
    );

    if (isAnyFieldEmpty) {
      // Set error message and prevent further action
      setError('Please fill all fields.');
      setLoading(false);
      return;
    }

    if (!addEventId) {
      setError('Please add event first.');
      setLoading(false);
      return;
    }
    // Process the featureList data and print in the required format
    const formattedData = featureList.map((feature) => ({
      event_id: addEventId, // Assuming addEventId holds the event ID
      icon_id: feature.icon_id,
      title: feature.title,
      description: feature.description,
    }));

    const response = await addEventFeatures(token, JSON.stringify({ features: formattedData, }));
    // Reset input field after processing
    if (!response) {
      setError("Oops! Event not added.please try again.")
    }
    else {
      onNext();
    }
    setLoading(false);
  };

  return (
    <section class='w-full p-4 rounded-md  '>
      {allIcons ? (
        <section class='w-full px-10 pt-5 pb-10 rounded-md  bg-white'>
          {/* {{-- title --}} */}
          <div class='flex space-x-3'>

            <h1 class='font-semibold text-md'>Add Features</h1>
          </div>

          {/* {{-- form  --}} */}
          <div class='flex flex-col gap-6 mt-5'>
            {/* rows */}
            {featureList.map((feature, index) => {
              return <div key={index} className="flex flex-row gap-5">
                {/* {{-- name --}} */}
                <div class='relative border rounded-md border-black px-2 py-4 w-full'>
                  <input
                    type='text'
                    name="title"
                    value={feature.title}
                    onChange={(e) => handleFeatureInputChange(index, e)}
                    class='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                  />
                  <p class='absolute -top-3 left-5 bg-white px-2'>Name</p>
                </div>
                {/* {{-- discription --}} */}
                <div class='relative border rounded-md border-black px-2 py-4 w-full'>
                  <input
                    type='text'
                    value={feature.description}
                    name="description"

                    onChange={(e) => handleFeatureInputChange(index, e)}
                    class='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                  />
                  <p class='absolute -top-3 left-5 bg-white px-2'>Discription</p>
                </div>
                {/* {{-- upload icon --}} */}
                <div class='relative border rounded-md border-black px-2 py-4 w-full'>
                  <Select
                    value={
                      feature.icon_id
                        ? options.find((option) => option.value === feature.icon_id)
                        : null
                    }
                    onChange={(value) => {
                      const selectedIconId = value ? value.value : null;
                      const updatedFeatureList = [...featureList];
                      updatedFeatureList[index] = {
                        ...updatedFeatureList[index],
                        icon_id: selectedIconId,
                      };
                      setFeatureList(updatedFeatureList);
                    }}

                    options={options}
                    placeholder=''
                    isClearable={true}
                    styles={customStyles}
                    components={{ SingleValue: customSingleValue }}
                  />
                  <p class='absolute -top-3 left-5 bg-white px-2'>
                    Select Icon
                  </p>
              
                </div>
                
                {index !== 0 && ( // Check if it's not the first row
                <div className='relative  px-2 py-10 flex items-center justify-center '>
                  <RiDeleteBin6Line
                    onClick={() => handleDeleteFeature(index)}
                    size={20}
                    className='absolute right-2 top-2 cursor-pointer text-red-500'
                  />
                </div>
              )}
              </div>
            })}
            {/* {{-- add accomodation button --}} */}
            <div class='flex items-center justify-end  w-full space-x-8'>
              <button  onClick={handleAddFeature} class='border px-10 py-2 shadow-md rounded-full text-white bg-[#8DC67B] text-md font-normal'>
                + Add Feature
              </button>
            </div>

            {error &&
            <p className="text-sm font-normal text-center text-red-500">
              {error}
            </p>}


            {/* {{-- cancel and save button --}} */}
            <div class='flex items-center justify-center  w-full space-x-8'>
            <Link to={"/all-events"} >
              <button class='border px-16 py-2 rounded-md border-black text-[#8DC67B] text-md font-semibold'>
                Cancel
              </button>
            </Link>
              <button disabled={loading} onClick={handleNext} class=' px-16 py-2 rounded-md  text-white bg-[#8DC67B] text-md font-semibold'>
                 {loading
                ? <div className="flex items-center justify-center w-full">
                  <ThreeDots
                    color="#ffffff"
                    height={20}
                    width={40}
                    className="py-2"
                  />
                </div>
                : 'Save'}
              </button>
            </div>
          </div>
        </section>
      ) : (
        <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
          <h5 className="font-bold text-center text-md">Loading ....</h5>
        </div>
      )}
    </section>
  )
}

export default AddFeatures
