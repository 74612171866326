import React, { useEffect, useRef, useState } from 'react';
import { useCategoriesContext } from '../../contexts/CategoriesContextProvider';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { ThreeDots } from 'react-loader-spinner';
import { useLocationContext } from '../../contexts/LocationContextProvider';
import { BsCamera } from "react-icons/bs";
import { useIconsContext } from '../../contexts/IconsContextProvider';
import { useNavigate, useParams } from "react-router-dom"
import ReactQuill from 'react-quill'; // Import React Quill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useBlogsContext } from '../../contexts/BlogsContextProvider';
import { IoIosArrowBack } from "react-icons/io";


function EditBlog() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [introduction, setIntroduction] = useState('');
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { token } = useAuthContext();
    const { allBlogs,
        getBlogs, updateBlogText } = useBlogsContext();



    const handleTitleChange = (e) => {
        setTitle(e.target.value);
        setError('');
    };

    const handleIntroductionChange = (e) => {
        setIntroduction(e.target.value);
        setError('');
    };

    const handleContentChange = (text) => {
        setContent(text);
        setError('');
    };


    const handleNextButtonClick = async () => {
        setLoading(true);
        if (!content) {
            setError('Please enter a content');
            setLoading(false);
            return;
        }
        if (!introduction) {
            setError('Please enter a introduction.');
            setLoading(false);
            return;
        }


        if (!title) {
            setError('Please enter a title.');
            setLoading(false);
            return;
        }



        // Perform action with the category name (in this case, logging to console)

        const data = {};
        data["title"] = title;
        data["content"] = content;
        data["introduction"] = introduction;

        // Additional actions or state changes can be performed here
        const response = await updateBlogText(token, id, data);
        // Reset input field after processing
        if (!response) {
            setError("Oops! Blog not added.please try again.")

        }
        else {
            setTitle("")
            setIntroduction("")
            setContent("")

            navigate("/blogs")

        }

        setLoading(false);

    };

    const fetchCurrentBlog = async () => {
        setLoading(true);
        const currentBlog = allBlogs.find(item => item.id == id);
        currentBlog && setTitle(currentBlog.title ? currentBlog.title : "")
        currentBlog && setIntroduction(currentBlog.introduction ? currentBlog.introduction : "")
        currentBlog && setContent(currentBlog.content ? currentBlog.content : "")
        setLoading(false);
    }

    const getAllEvents = async () => {
        setLoading(true);
        await getBlogs(token);
        setLoading(false);
    }

    useEffect(() => {

        getAllEvents();
    }, []);

    useEffect(() => {
        fetchCurrentBlog();
    }, [id]);

    useEffect(() => {
        fetchCurrentBlog();
    }, [allBlogs]);


    return (
        <div>
            <div className="flex items-center justify-center p-3">

                <div className="relative z-50 w-full mx-auto ">
                    <div className="relative px-6 py-6 bg-white rounded-lg shadow-lg">
                        <button
                            onClick={() => navigate("/blogs/view/"+id)}
                            className="absolute left-4 z-50 flex mb-4 items-center justify-center p-2 bg-white rounded-full shadow-md w-min h-min"
                        >
                            <IoIosArrowBack
                                size={28}
                                className="text-black cursor-pointer"
                            />
                        </button>
                        <div className='flex gap-3 items-center justify-center w-full mb-14'>
                            <h1 className='font-semibold text-2xl text-center'>Update Blog</h1>
                        </div>

                        <div className='flex flex-col items-center gap-10 mt-5'>

                            {/* title */}
                            <div className='relative border rounded-md border-black px-2 py-4 w-full'>
                                <input
                                    type='text'
                                    value={title}
                                    onChange={handleTitleChange}
                                    className='w-full p-0 border-0 text-base font-normal focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                                    placeholder='Title'
                                />
                                <p className='absolute -top-3 left-5 bg-white px-2'>Title</p>
                            </div>

                            {/* introduction */}

                            <div className='relative border rounded-md border-black px-2 py-4 w-full'>
                                <textarea
                                    value={introduction}
                                    onChange={handleIntroductionChange}
                                    className='w-full p-0 border-0 text-base font-normal focus:outline-none focus:ring-0 focus:border-0 focus:bg-white resize-none'
                                    placeholder='Introduction'
                                />
                                <p className='absolute -top-3 left-5 bg-white px-2'>Introduction</p>
                            </div>

                            {/* content */}
                            <div className='w-full'>
                                <ReactQuill
                                    value={content}
                                    onChange={handleContentChange}
                                    className='h-64 w-full'
                                    placeholder='Start writing your content...'
                                />
                            </div>

                            <div className='flex flex-col items-center justify-center w-full gap-2 mt-10'>
                                {error && <p className='text-red-500'>{error}</p>}
                                <button
                                    onClick={handleNextButtonClick}
                                    className='w-full py-2 rounded-md text-white bg-[#8DC67B] text-md font-semibold'
                                >
                                    {loading
                                        ? <div className="flex items-center justify-center w-full">
                                            <ThreeDots
                                                color="#ffffff"
                                                height={20}
                                                width={40}
                                                className="py-2"
                                            />
                                        </div>
                                        : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditBlog;
