// BlogsContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader, multiFormHeader } from "../apis/header";

const BlogsContext = createContext();

export const BlogsContextProvider = ({ children }) => {
  const [allBlogs, setAllBlogs] = useState(null);

  const getBlogs = async (token) => {
    try {
      const headers = jsonHeader(token)

      const response = await ApiTemplate("get", "/api/v1/get/posts", {}, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setAllBlogs(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const addBlog = async (token, blogData) => {
    try {
      const headers = multiFormHeader(token);

      const response = await ApiTemplate("post", "/api/v1/store/post", blogData, headers);
      if (response && response["success"] === true) {

        await getBlogs(token);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const deleteBlog = async (token, blogId) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", "/api/v1/remove/post/"+blogId, {}, headers);
      if (response && response["success"] === true) {
        await getBlogs(token);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const updateBlogText = async (token, blogId, blogData) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("post", "/api/v1/update/post/"+blogId, blogData, headers);
      if (response && response["success"] === true) {
        
        await getBlogs(token);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const updateBlogImage = async (token, blogId, blogData) => {
    try {
      const headers = multiFormHeader(token);

      const response = await ApiTemplate("post", "/api/v1/update/post/media/"+blogId, blogData, headers);
      if (response && response["success"] === true) {
        
        await getBlogs(token);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  return (
    <BlogsContext.Provider
      value={{
        allBlogs,
        getBlogs,
        addBlog,
        deleteBlog,
        updateBlogImage,
        updateBlogText
      }}
    >
      {children}
    </BlogsContext.Provider>
  );
};

export const useBlogsContext = () => {
  const blogsContextValue = useContext(BlogsContext);
  return blogsContextValue;
};
