import React, { useState } from 'react';
import { useCategoriesContext } from '../../contexts/CategoriesContextProvider';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { ThreeDots } from 'react-loader-spinner';
import { useEffect } from 'react';

function EditCategory({
    showModel,
    setShowModel,
    id,
    name,
}) {
    const [categoryName, setCategoryName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { updateCategory } = useCategoriesContext();
    const { token } = useAuthContext();

    const handleInputChange = (e) => {
        setCategoryName(e.target.value);
        setError(''); // Clearing any previous error when user starts typing
    };

    useEffect(() => {
        setCategoryName(name);
    }, [name])

    const handleNextButtonClick = async () => {
        setLoading(true);
        if (!categoryName) {
            setError('Please enter a category name');
            setLoading(false);
            return;
        }

        // Perform action with the category name (in this case, logging to console)
        console.log('Category Name:', categoryName);

        // Additional actions or state changes can be performed here
        const response = await updateCategory(token, id,JSON.stringify({ name: categoryName }));
        // Reset input field after processing
        if (!response) {
            setError("Oops! Category not updated.please try again.")

        }
        else {
            setShowModel(false);

        }
       
        setLoading(false);

    };

    return (
        <div>{showModel &&
            <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div
                    onClick={() => setShowModel(false)}
                    className="fixed inset-0 bg-black opacity-[66%]"
                />
                <div className="relative z-50 md:w-auto w-[95%] md:min-w-[28rem] mx-auto my-6">
                    <div className="relative px-6 py-6 bg-white rounded-lg shadow-lg">
                        <div className='flex gap-3 items-center justify-center w-full mb-14'>
                            <h1 className='font-semibold text-2xl text-center'>Update Category</h1>
                        </div>

                        <div className='flex flex-col items-center gap-10 mt-5'>
                            <div className='relative border rounded-md border-black px-2 py-4 w-full'>
                                <input
                                    type='text'
                                    value={categoryName}
                                    onChange={handleInputChange}
                                    className='w-full p-0 border-0 text-base font-normal focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                                />
                                <p className='absolute -top-3 left-5 bg-white px-2'>Name</p>
                            </div>



                            <div className='flex flex-col items-center justify-center w-full gap-2'>
                                {error && <p className='text-red-500'>{error}</p>}
                                <button
                                    onClick={handleNextButtonClick}
                                    className='w-full py-2 rounded-md text-white bg-[#8DC67B] text-md font-semibold'
                                >
                                    {loading
                                        ? <div className="flex items-center justify-center w-full">
                                            <ThreeDots
                                                color="#ffffff"
                                                height={20}
                                                width={40}
                                                className="py-2"
                                            />
                                        </div>
                                        : 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default EditCategory;
