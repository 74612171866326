import React, { useEffect, useState } from 'react';
import { useCategoriesContext } from '../../contexts/CategoriesContextProvider';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { Link } from 'react-router-dom';
import AddCategory from './add_category';
import EditCategory from './edit_category';
import ConfirmationDeletePopUp from '../../components/confirmation_delete_popup';

function AllCategories() {

  const { allCategories, getCategories } = useCategoriesContext();
  const { token } = useAuthContext();

  // Define state variables and functions to handle dropdown toggles, search, etc.
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const [showAddCategoryPopup, setShowAddCategoryPopup] = useState(false);

  const getAllCategories = async () => {
    await getCategories(token);
  }

  useEffect(() => {
    getAllCategories();
  }, []);



  // Function to toggle the Actions dropdown
  const toggleActionsDropdown = () => {
    setShowActionsDropdown(!showActionsDropdown);
  };

  // Function to toggle the Filter dropdown
  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  console.log(allCategories)

  return (
    <div className='px-5 py-10'>
      {allCategories ?
        <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
          <h1 className="font-semibold text-3xl px-4 pt-4">CATEGORIES</h1>

          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
              {/* search */}
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="block w-full py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 ps-10 pe-3"
                    placeholder="Search"
                    required
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </form>
            </div>

            {/* Actions and Filter Buttons */}
            <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">

              <button
                type="button"
                onClick={() => setShowAddCategoryPopup(true)}
                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300"
              >
                <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  />
                </svg>
                Add Category
              </button>


            </div>
          </div>

          <div className="overflow-x-auto ">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Id
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Name
                  </th>

                  <th scope="col" className="px-4 py-3 text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Table rows */}
                {
                  allCategories && allCategories.map((category, index) => {
                    return <CategoryRow id={index} category={category} />
                  })
                }

                {/* Map more rows here */}
              </tbody>
            </table>
          </div>
          <nav className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0" aria-label="Table navigation">
            <span className="text-sm font-normal text-gray-500">
              Showing
              <span className="mx-1 font-semibold text-gray-900">1-10</span>
              of
              <span className="mx-1 font-semibold text-gray-900">1000</span>
            </span>
          </nav>
        </div> :
        <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
          <h5 className="font-bold text-center text-md">Loading ....</h5>
        </div>}

      {/* add category */}
      <AddCategory showModel={showAddCategoryPopup} setShowModel={setShowAddCategoryPopup} />

    </div>
  );
}

export default AllCategories;


function CategoryRow({ id, category }) {
  const [showUpdateCategoryPopup, setShowUpdateCategoryPopup] = useState(false);
  const [showDeleteCategoryPopup, setShowDeleteCategoryPopup] = useState(false);

  const { deleteCategory } = useCategoriesContext();
  const { token } = useAuthContext();

  const handleDeleteButtonClick = async (categoryId) => {

    // Additional actions or state changes can be performed here
    const response = await deleteCategory(token, categoryId);
    // Reset input field after processing
    if (response) {

      setShowDeleteCategoryPopup(false);
    }

  };

  return <tr key={id} className="border-b">
    <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap">
      {category.id}
    </td>
    <td className="px-4 py-3">{category.name}</td>

    <td className="px-2 py-3">
      <ul className="flex items-center justify-center gap-3 py-1 text-sm text-gray-700">

        <li className="cursor-pointer" onClick={() => setShowUpdateCategoryPopup(true)}>
          {/* <Link to={"/categories/"+category.id}> */}
          <img src={'images/sidebar/Edit.svg'} alt="Edit" />
          {/* </Link> */}
        </li>
        <li className="cursor-pointer" onClick={() => setShowDeleteCategoryPopup(true)}>
          {/* <a href="#"> */}
          <img src={'images/sidebar/Delete.svg'} alt="Delete" />
          {/* </a> */}
        </li>
        {/* add category */}
        <EditCategory id={category.id} name={category.name} showModel={showUpdateCategoryPopup} setShowModel={setShowUpdateCategoryPopup} />
        {/* delete popup */}
        <ConfirmationDeletePopUp handleCancel={()=>setShowDeleteCategoryPopup(false)} handleDelete={()=>handleDeleteButtonClick(category.id)} title={"Are you sure you want to delete this category."} showModel={showDeleteCategoryPopup} setShowModel={setShowDeleteCategoryPopup} />
      </ul>
    </td>
  </tr>
}
