import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../../../contexts/AuthContextProvider';
import { useCategoriesContext } from '../../../contexts/CategoriesContextProvider';
import { useCoordinatesContext } from '../../../contexts/CoordinatesContextProvider';
import { ThreeDots } from 'react-loader-spinner';
import { useEventContext } from '../../../contexts/EventContextProvider';
import { useLocationContext } from '../../../contexts/LocationContextProvider';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { baseUrl } from './../../../apis/base_url';
import { IoIosArrowBack } from 'react-icons/io';


function EditEvent() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();
  const { allCoordinates, getCoordinates } = useCoordinatesContext();
  const { allCategories, getCategories } = useCategoriesContext();
  const { getLocations, allLocations } = useLocationContext();
  const { token } = useAuthContext();
  const { addEvent, getEventbyId,
    singleEventId, } = useEventContext();
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedCardImage, setSelectedCardImage] = useState(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    category: null,
    coordinators: null,
    numberOfPersons: '',
    title: '',
    description: '',
    location: null,
    price: '',
    duration: '',
    startDate: '',
    endDate: '',
    bannerImage: null,
    cardImage: null,
  });


  const fetchAllEvents = async () => {
    await getEventbyId(token, id);
  }

  function formatDate(dateString) {
    const formattedDateString = dateString.replace(/\b(?:st|nd|rd|th)\b/g, '');
    const parsedDate = moment(formattedDateString, 'dddd,MMMM Do,YYYY').toDate();
    return moment(parsedDate).format('YYYY-MM-DD');
  }

  const setDataFromSingleEventId = () => {
    console.log("start date coming from api: ", singleEventId && formatDate(singleEventId.event?.date_at.split(" - ")[0]))
    singleEventId && singleEventId.event && setFormData({
      category: singleEventId.event.category_id ? singleEventId.event.category_id : null,
      coordinators: singleEventId.event.coordinator_id ? singleEventId.event.coordinator_id : null,
      numberOfPersons: singleEventId.event?.total_group_members,
      title: singleEventId.event?.title,
      description: singleEventId.event?.description,
      price: singleEventId.event?.price,
      duration: singleEventId.event?.duration,
      startDate: formatDate(singleEventId.event?.date_at.split("-")[0]),
      endDate: formatDate(singleEventId.event?.date_at.split("-")[1]),
      bannerImage: singleEventId.event.banner_image ? singleEventId.event.banner_image : null,
      cardImage: singleEventId.event.card_image ? singleEventId.event.card_image : null,
      location: singleEventId.event.location ? singleEventId.event.location : null,

    })
  }

  useEffect(() => {
    fetchAllEvents();
  }, [id]);


  useEffect(() => {
    setDataFromSingleEventId();
  }, [singleEventId]);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      // Handling file inputs differently
      if (name === 'bannerImage') {
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64String = e.target.result;
          setSelectedBanner({ file: files[0], base64: base64String });

        };

        reader.readAsDataURL(files[0]);
      } else if (name === 'cardImage') {
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64String = e.target.result;
          setSelectedCardImage({ file: files[0], base64: base64String });

        };

        reader.readAsDataURL(files[0]);
      }
      else setFormData({
        ...formData,
        [name]: files[0], // Assuming only a single file is selected
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleNextClick = async () => {
    setError(null);
    setLoading(true);
    const {
      category,
      coordinators,
      numberOfPersons,
      title,
      description,
      price,
      duration,
      startDate,
      endDate,
      bannerImage,
      cardImage,
      location,
    } = formData;

    // Check if any required field is empty
    if (
      !category ||
      !coordinators ||
      !location ||
      !numberOfPersons ||
      !title ||
      !description ||
      !price ||
      !duration ||
      !startDate ||
      !endDate ||
      !bannerImage ||
      !cardImage
    ) {
      setError("Please fill all fields."); // Set error state to true to indicate incomplete fields
      setLoading(false);
      return; // Prevent moving to the next step
    }

    let data = new FormData();
    data.append('category_id', category);
    data.append('coordinator_id', coordinators);
    data.append('title', title);
    data.append('description', description);
    data.append('price', price);
    data.append('start_date', startDate);
    data.append('end_date', endDate);
    data.append('duration', duration);
    data.append('total_group_members', numberOfPersons);
    data.append('location', location);
    data.append('banner_image', bannerImage);
    data.append('card_image', cardImage);

    // Additional actions or state changes can be performed here
    const response = await addEvent(token, data);
    // Reset input field after processing
    if (!response) {
      setError("Oops! Event not added.please try again.")
    }
    else {
      // onNext();
    }

    console.log('Form Data:', formData);
    setLoading(false);

  };

  const getAllOptions = async () => {
    if (allCategories == null) {
      await getCategories(token)
    }
    if (allCoordinates == null) {
      await getCoordinates(token)
    }
    if (allLocations == null) {
      await getLocations(token)
    }
    console.log("allCoordinates", allCoordinates)
    console.log("allCategories", allCategories)
  }


  useEffect(() => {
    getAllOptions();
  }, [])


  function formatDateForInput(dateString) {
    const [month, day, year] = dateString.split('/');
    const formattedDateForInput = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    return formattedDateForInput;
  }


  return (
    <section class='w-full p-4 rounded-md  '>
      <section class='w-full px-10 pt-5 pb-10 rounded-md  bg-white'>
        {/* {{-- title --}} */}
        <div class='flex gap-3 items-center mb-4'>
          <button
            onClick={() => navigate("/event/view/"+id)}
            className=" left-0 z-50 flex  items-center justify-center p-2 bg-white rounded-full shadow-md w-min h-min"
          >
            <IoIosArrowBack
              size={28}
              className="text-black cursor-pointer"
            />
          </button>
          <h1 class='font-semibold text-2xl'>Edit Event Details</h1>
        </div>

        {/* {{-- form  --}} */}
        <div class='flex flex-col space-y-6 mt-5'>
          {/* {{-- event category and no of persons --}} */}
          <div class='flex items-center w-full space-x-8'>
            <div class='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <select name='category' value={formData.category}
                onChange={handleInputChange} class='border-none focus:outline-0 outline-none focus:ring-0 bg-transparent active:border-none w-full'>
                <option disabled selected>
                  Select Category
                </option>
                {
                  allCategories && allCategories.map(category => (
                    <option value={category.id}>{category.name}</option>

                  ))
                }

              </select>
              <p class='absolute -top-3 left-5 bg-white px-2'>
                Select Category
              </p>
            </div>
            <div class='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <select name='coordinators' value={formData.coordinators}
                onChange={handleInputChange} class='border-none focus:outline-0 outline-none focus:ring-0 bg-transparent active:border-none w-full'>
                <option disabled selected>
                  Select Coordinators
                </option>
                {
                  allCoordinates && allCoordinates.map(coordinate => (
                    <option value={coordinate.id}>{coordinate.name}</option>

                  ))
                }
              </select>
              <p class='absolute -top-3 left-5 bg-white px-2'>
                Select Coordinators
              </p>
            </div>

          </div>
          <div class='flex items-center w-full space-x-8'>

            <div class='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <select name='location' value={formData.location}
                onChange={handleInputChange} class='border-none focus:outline-0 outline-none focus:ring-0 bg-transparent active:border-none w-full'>
                <option disabled selected>
                  Select Location
                </option>
                {
                  allLocations && allLocations.map(location => (
                    <option value={location.name}>{location.name}</option>

                  ))
                }
              </select>
              <p class='absolute -top-3 left-5 bg-white px-2'>
                Select Location
              </p>
            </div>
            <div class='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <input
                type='text'
                name='numberOfPersons'
                value={formData.numberOfPersons}
                onChange={handleInputChange}
                class='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p class='absolute -top-3 left-5 bg-white px-2'>No. of Persons</p>
            </div>
          </div>
          {/* {{-- title --}} */}
          <div class='relative border rounded-md border-black px-2 py-4 w-full'>
            <input
              type='text'
              name='title'
              value={formData.title}
              onChange={handleInputChange}
              class='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
            />
            <p class='absolute -top-3 left-5 bg-white px-2'>Title</p>
          </div>
          {/* {{-- description --}} */}
          <div class='relative border rounded-md border-black px-2 py-4 w-full'>
            <input
              type='text'
              name='description'
              value={formData.description}
              onChange={handleInputChange}
              class='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
            />
            <p class='absolute -top-3 left-5 bg-white px-2'>Description</p>
          </div>
          {/* {{-- price and duration --}} */}
          <div class='flex items-center w-full space-x-8'>
            <div class='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <input
                type='text'
                name='price'
                value={formData.price}
                onChange={handleInputChange}
                class='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p class='absolute -top-3 left-5 bg-white px-2'>Price</p>
            </div>
            <div class='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <input
                type='text'
                name='duration'
                value={formData.duration}
                onChange={handleInputChange}
                class='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p class='absolute -top-3 left-5 bg-white px-2'>Duration</p>
            </div>
          </div>
          {/* {{-- start date and end date --}} */}
          <div class='flex items-center w-full space-x-8'>
            <div class='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <input
                type='date'
                name='startDate'
                value={formData.startDate}
                onChange={handleInputChange}
                class='w-full appearance-none p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p class='absolute -top-3 left-5 bg-white px-2'>Start Date</p>
            </div>
            <div class='relative border rounded-md border-black px-2 py-4 w-1/2'>
              <input
                type='date'
                name='endDate'
                value={formData.endDate}
                onChange={handleInputChange}
                class='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p class='absolute -top-3 left-5 bg-white px-2'>End Date</p>
            </div>
          </div>
          {/* {{-- upload banner image and upload card image --}} */}
          <div class='flex items-center w-full space-x-8'>
            <div class='relative border rounded-md border-black px-2 py-4 w-1/2'>
              {
                selectedBanner ?
                  <img
                    src={selectedBanner.base64}
                    alt='bannerImage'
                    class='w-32 h-32 object-cover mb-3'
                  /> :
                  formData.bannerImage && <img
                    src={baseUrl + "/" + formData.bannerImage.path + "/" + formData.bannerImage.name}
                    alt='bannerImage'
                    class='w-32 h-32 object-cover mb-3'
                  />
              }
              <input
                type='file'
                name='bannerImage'
                onChange={handleInputChange}
                class='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p class='absolute -top-3 left-5 bg-white px-2'>Banner Image</p>
            </div>
            <div class='relative border rounded-md border-black px-2 py-4 w-1/2'>
              {
                selectedCardImage ?
                  <img
                    src={selectedCardImage.base64}
                    alt='bannerImage'
                    class='w-32 h-32 object-cover mb-3'
                  /> :
                  formData.bannerImage && <img
                    src={baseUrl + "/" + formData.cardImage.path + "/" + formData.cardImage.name}
                    alt='bannerImage'
                    class='w-32 h-32 object-cover mb-3'
                  />
              }
              <input
                type='file'
                name='cardImage'
                onChange={handleInputChange}
                class='w-full p-0 border-0 text-base font-normal  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
              />
              <p class='absolute -top-3 left-5 bg-white px-2'>Card Image</p>
            </div>
          </div>

          {error &&
            <p className="text-sm font-normal text-center text-red-500">
              {error}
            </p>}
          {/* {{-- cancel and save button --}} */}
          <div class='flex items-center justify-end  w-full space-x-8'>
            {/* <button class='border px-16 py-2 rounded-md border-black text-[#8DC67B] text-md font-semibold'>
              Cancel
            </button> */}
            <button onClick={handleNextClick} class=' px-16 py-2 rounded-md  text-white bg-[#8DC67B] text-md font-semibold'>
              {loading
                ? <div className="flex items-center justify-center w-full">
                  <ThreeDots
                    color="#ffffff"
                    height={20}
                    width={40}
                    className="py-2"
                  />
                </div>
                : 'Save'}
            </button>
          </div>
        </div>
      </section>
    </section>
  )
}

export default EditEvent
