import React, {createContext, useContext, useState} from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader } from "../apis/header";

const FinanceContext=createContext();

export const FinanceContextProvider = ({children}) =>{
    const [financeList, setFinanceList]=useState(null);
    const getList = async (token, page, itemsPerPage) => {
        try {
            const headers = jsonHeader(token)

            const response = await ApiTemplate("get", `/api/v1/finance/list?page=${page}&limit=${itemsPerPage}`, {}, headers);
            if (response && response["success"] === true) {
                console.log("response :", response?.data?.data);
                setFinanceList(response?.data?.data)
                // setAllIcons(response["data"]);
            } else {
                console.log(response["message"], response.message);
            }
        } catch (error) {
            console.error("Error during API call:", error);
        }
    };

    return (
        <FinanceContext.Provider
            value={{
                financeList,
                getList
            }}
        >
            {children}
        </FinanceContext.Provider>
    );
}

export const useFinanceContext = () => {
    const financeContextValue = useContext(FinanceContext);
    return financeContextValue;
};
