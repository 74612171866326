import React from 'react'
import Layout from '../../layout'

function Dashboard() {
  return (
    <div>
    this is dashboard

    </div>
  )
}

export default Dashboard