

import { Link, useParams } from "react-router-dom";

import { ColorRing } from 'react-loader-spinner'

import { useEffect, useRef, useState } from "react";
import { useEventContext } from "../../contexts/EventContextProvider";
import { baseUrl } from "../../apis/base_url";
import { useBlogsContext } from "../../contexts/BlogsContextProvider";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { BsCamera } from "react-icons/bs";
import ConfirmationDeletePopUp from "../../components/confirmation_delete_popup";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";


const ViewBlog = () => {

    const { id } = useParams();
    const [currentBlog, setCurrentBlog] = useState(null)
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const { deleteBlog } = useBlogsContext();
    const { token } = useAuthContext();
    const { allBlogs,
        getBlogs, updateBlogImage } = useBlogsContext();
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [imageLoading, setImageLoading] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);
    const navigate = useNavigate();


    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                setSelectedImage({ file: file, base64: e.target.result });
                setImageLoading(true)
                const data = new FormData();
                data.append("post_image", file)
                await updateBlogImage(token, id, data);
                setImageLoading(false)
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleDeleteButtonClick = async (blogId) => {
        // Additional actions or state changes can be performed here
        const response = await deleteBlog(token, blogId);
        if (response) {
            setShowDeletePopup(false);
        }

    };

    const fetchCurrentBlog = async () => {
        setLoading(true);
        allBlogs && setCurrentBlog(allBlogs.find(item => item.id == id))
        setLoading(false);
    }

    const getAllEvents = async () => {
        setLoading(true);
        await getBlogs(token);
        setLoading(false);
    }

    useEffect(() => {

        getAllEvents();
    }, []);

    useEffect(() => {
        fetchCurrentBlog();
    }, [id]);

    useEffect(() => {
        fetchCurrentBlog();
    }, [allBlogs]);

    return (
        <section>
            {currentBlog ? <div>
                <div className="md:w-[90%] relative w-full md:p-0 px-4 my-8 mx-auto flex flex-col gap-4">

                    <div className="relative">
                        <button
                            onClick={() => navigate("/blogs")}
                            className=" left-0 z-50 flex mb-4 items-center justify-center p-2 bg-white rounded-full shadow-md w-min h-min"
                        >
                            <IoIosArrowBack
                                size={28}
                                className="text-black cursor-pointer"
                            />
                        </button>
                        {imageLoading
                            ? <div className=" relative w-full h-[18rem] rounded-md ">

                                <img src={baseUrl + "/" + currentBlog.image.path + "/" + currentBlog.image.name} alt="Blog" className="object-cover object-center w-full h-full rounded-md " />
                                <div className="absolute w-full h-full top-0 left-0 rounded-md flex items-center justify-center bg-opacity-50 bg-black"  >
                                    <div className="flex items-center justify-center w-full h-full my-20 text-center"><ColorRing
                                        visible={true}
                                        height="80"
                                        width="80"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                        colors={["white", "white", "white", "white", "white", "white"]}
                                    /></div>
                                </div>

                            </div> :
                            <img src={baseUrl + "/" + currentBlog.image.path + "/" + currentBlog.image.name} alt="Blog" className="object-cover object-center w-full h-[18rem] rounded-md " />
                        }  <div onClick={handleImageUploadClick} className="absolute bottom-2 right-2 z-50 flex items-center justify-center p-2 bg-white rounded-full shadow-md w-min h-min">
                            {imageLoading
                                ? null : <BsCamera
                                    size={28}
                                    className="text-gray-500 cursor-pointer"

                                />}
                        </div>
                        <input
                            type="file"
                            id="image"
                            ref={fileInputRef}
                            accept="image/*"
                            className="hidden"
                            onChange={handleImageChange}
                        />
                    </div>
                    <h1
                        className="rounded-full  font-semibold text-center text-3xl pt-1"

                    > {currentBlog?.title} </h1>

                    <h1
                        className="rounded-full  font-semibold  text-2xl pt-1"

                    > Introduction: </h1>

                    <p
                        className="rounded-full  font-normal  text-base pt-1"

                    > {currentBlog?.introduction} </p>



                    <h1
                        className="rounded-full  font-semibold  text-2xl pt-1"

                    >  Content:</h1>



                    <div className="mt-4 flex flex-col gap-2 blogContent" dangerouslySetInnerHTML={{ __html: currentBlog.content }} />

                    <div className="w-full flex gap-4 items-center justify-center mt-5">
                        <Link to={"/blogs/edit/" + id} ><button className="px-14 py-2 bg-white border-black border-2 text-black rounded-md">Edit</button></Link>
                        <button onClick={() => setShowDeletePopup(true)} className="px-12 py-2 bg-black border-black border-2 text-white rounded-md">Delete</button>
                    </div>
                    <ConfirmationDeletePopUp handleCancel={() => setShowDeletePopup(false)} handleDelete={() => handleDeleteButtonClick(id)} title={"Are you sure you want to delete this data."} showModel={showDeletePopup} setShowModel={setShowDeletePopup} />
                </div>
            </div> :
                loading ? <h1 className="my-10 text-base text-center w-full font-bold">Loading ...</h1> : <h1 className="my-10 text-base text-center w-full font-bold">No blog found.</h1>

            }
        </section>
    );
};

export default ViewBlog;
